<template>
<div class="font-picker-wrapper">
  <div class="form-group">
    <font-picker
      v-if="renderComponent"
      :options="options"
      api-key="AIzaSyBTf7G8H21i7jA3cc-nGM5ztFOWHmf8iVo"
      :active-font="font"
      @change="changeFont"
    ></font-picker>
  </div>

  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="1" id="manualSelection" @change="forceRerender()" v-model="manualSelection" name="manualSelection">
    <label class="form-check-label" for="manualSelection" @click="manualSelection = !manualSelection; forceRerender();">
        Show all available fonts
    </label>
  </div>

  <div class="card" v-if="manualSelection">
    <div class="card-body">
      <div>
        <div class="mb-2">
          <label>Languages</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="all" id="all" @change="forceRerender()" v-model="fontLanguage" name="fontLanguage">
            <label class="form-check-label" for="sans_serif">All</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="arabic" id="arabic" @change="forceRerender()" v-model="fontLanguage" name="fontLanguage">
            <label class="form-check-label" for="sans_serif">Arabic</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="japanese" id="japanese" @change="forceRerender()" v-model="fontLanguage" name="fontLanguage">
            <label class="form-check-label" for="sans_serif">Japanese</label>
          </div>
        </div>
        <div class="mb-2">
          <label>Categories</label>
          <div class="form-check">
            <input class="form-check-input" :disabled="fontLanguage !== 'all'" type="checkbox" value="1" id="sans_serif" @change="forceRerender()" v-model="fontCategories[0]" name="sans_serif">
            <label class="form-check-label" for="sans_serif">Sans Serif</label>
          </div>


          <div class="form-check">
              <input class="form-check-input" :disabled="fontLanguage !== 'all'" type="checkbox" value="1" id="serif" @change="forceRerender()" v-model="fontCategories[1]" name="serif">
              <label class="form-check-label" for="serif">Serif</label>
          </div>

          <div class="form-check">
              <input class="form-check-input" :disabled="fontLanguage !== 'all'" type="checkbox" value="1" id="display" @change="forceRerender()" v-model="fontCategories[2]" name="display">
              <label class="form-check-label" for="display">Display</label>
          </div>

          <div class="form-check">
              <input class="form-check-input" :disabled="fontLanguage !== 'all'" type="checkbox" value="1" id="handwriting" @change="forceRerender()" v-model="fontCategories[3]" name="handwriting">
              <label class="form-check-label" for="handwriting">Handwriting</label>
          </div>

          <div class="form-check">
              <input class="form-check-input" :disabled="fontLanguage !== 'all'" type="checkbox" value="1" id="monospace" @change="forceRerender()" v-model="fontCategories[4]" name="monospace">
              <label class="form-check-label" for="monospace">Monospace</label>
          </div>
        </div>
        <div class="mb-2">
          <label>Sort</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="alphabetical" id="alphabetical" @change="forceRerender()" v-model="options.sort" name="alphabetical">
            <label class="form-check-label" for="alphabetical">Alphabetical</label>
          </div>

          <div class="form-check">
            <input class="form-check-input" type="radio" value="popularity" id="popularity" @change="forceRerender()" v-model="options.sort" name="popularity">
            <label class="form-check-label" for="popularity">Popularity</label>
          </div>

        </div>

        <div>
          <label>Custom Font</label>
          <div class="form-group">
            <input type="text" class="form-control form-control-sm" id="customFont" placeholder="Enter font name" v-model="options.customFont" name="customFont">
          </div>
          <button class="btn btn-primary btn-sm" :disabled="!options.customFont" @click="applyCustomFont(options.customFont)">Apply</button>
        </div>
      </div>

    </div>
  </div>



</div>
</template>

<script>
import FontPicker from 'font-picker-vue';

export default {
  name: 'GoogleFontPicker',
  props: {
    selectedFonts: { type:Array, default: function () { return [] }},
    defaultFont: { type:String, default: 'Caveat' },
    name: { type:String, default: '' }
    },
  components: { FontPicker },
  data: function() {
      return {
        renderComponent: true,
        font: null,
        fontCategories: [true, true, true, true, true],
        fontLanguage: 'all',
        manualSelection: false,
        options: {
          name: this.name,
          families: this.selectedFonts,
          categories: ['sans-serif', 'serif', 'display', 'handwriting', 'monospace'],
          limit: 1500,
          sort: 'alphabetical'
        }
      }
  },
  created() {
    this.font = this.defaultFont;
  },
  methods: {
    changeFont(font) {
      this.font = font.family;
      this.$emit('changeFont', this.font);
    },
    applyCustomFont(font) {
      this.$emit('customFont', font);
    },
    forceRerender() {
        let categoryArray = [];

        if (this.fontLanguage !== 'all') {
          this.fontCategories = [true, true, true, true, true];
        }

        if (this.fontCategories[0]) {
            categoryArray.push('sans-serif');
        }

        if (this.fontCategories[1]) {
            categoryArray.push('serif');
        }

        if (this.fontCategories[2]) {
            categoryArray.push('display');
        }

        if (this.fontCategories[3]) {
            categoryArray.push('handwriting');
        }

        if (this.fontCategories[4]) {
            categoryArray.push('monospace');
        }

        if (this.manualSelection) {
            this.options.families = null;

            if (this.fontLanguage === 'japanese') {
              this.selectedFonts = ["Oleo Script Swash Caps", "M PLUS Rounded 1c", "Kaisei Decol", "Yusei Magic", "DotGothic16", "Yuji Syuku", "Yuji Mai"];
            }

            if (this.fontLanguage === 'arabic') {
              this.selectedFonts = ["Tajawal", "Amiri", "Almarai", "Cairo", "El Messiri", "Lalezar", "Markazi Text"];
            }

            if (this.fontLanguage !== 'all') {
              this.options.families = this.selectedFonts;
              this.changeFont(this.selectedFonts[0]);
            }

            if (categoryArray.length) {
              this.options.categories = categoryArray;
            } else {
              this.options.categories = null;
            }

        } else {
            this.options.families = this.selectedFonts;
            this.options.categories = null;
        }

        // Remove my-component from the DOM
        this.renderComponent = false;

        // If you like promises better you can
        // also use nextTick this way
        this.$nextTick().then(() => {
            // Add the component back in
            this.renderComponent = true;
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
label {
  font-size:12px;
}

.form-check-label{
  font-size:14px;
  position:relative;
  top:-2px;
}

.font-picker {
  width: 100%;
}

.font-picker-wrapper {
    padding-bottom:5px;
}

.card {
  margin-top:10px;
}

.card-body {
  padding: 0 10px 5px 10px;
}

</style>

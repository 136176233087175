<template>
  <div class="container-fluid">
    <div class="page">
      <div v-if="loading" class="text-center">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div class="component-wrapper" v-if="selectedType && selectedVariant">
        <div class="row component-header">
          <div class="col-xs-12 col-lg-3 btn-back-wrapper">
            <router-link :to="'/generator/' + this.selectedType"
              ><button class="btn btn-default btn-back"><i class="la la-angle-left"></i> Back</button></router-link
            >
          </div>
          <div class="col-xs-12 col-lg-6">
            <div class="text-center btn-download">
              <button class="btn btn-primary" v-if="!isFoundry" v-on:click="download"><i class="la la-download"></i> Download Handout</button>
              <button class="btn btn-primary" v-if="isFoundry" v-on:click="importToFoundry"><i class="la la-download"></i> Import Handout</button>
              <span v-if="!isPatron && !isFoundry">&nbsp;<LoginPatreon type="small"></LoginPatreon></span>
            </div>
          </div>
          <div class="col-xs-12 col-lg-3 text-right">
            <!-- <a href="#" class="help"><i class="la la-question-circle"></i></a> -->
            <div class="handoutSelection">
              <div class="handoutSwitcher" @click="showOtherHandouts = !showOtherHandouts">
                Select a handout <i class="la la-angle-up select-handout-icon" v-if="showOtherHandouts"></i><i class="la la-angle-down select-handout-icon" v-if="!showOtherHandouts"></i>
              </div>
              <div class="otherHandouts" v-if="showOtherHandouts">
                <template v-for="category in handouts">
                  <div :key="category.name" v-if="category.enabled" class="category">
                    <h3 @click="selectedHandoutCategory = selectedHandoutCategory === category.folderName ? '' : category.folderName">{{ category.name }}</h3>
                    <div class="handouts" v-if="selectedHandoutCategory === category.folderName">
                      <div class="handout" v-on:click="goToHandout(category.folderName, handout.name)" v-for="handout in category.variants" :key="handout.name">
                        <img :src="'/images/types/' + category.folderName + '/' + handout.name + '.jpg'" :alt="category.name + ' - ' + handout.name" width="75" loading="lazy" />
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        
        <Canvas :type="selectedType" :variant="selectedVariant" />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import generatorData from '@/models/generator.json';
import Canvas from '@/components/Canvas.vue';
import LoginPatreon from '@/components/LoginPatreon.vue';

export default {
  name: 'Generator',
  components: {
    Canvas,
    LoginPatreon,
  },
  data: function() {
    return {
      loading: true,
      selectedType: '',
      selectedVariant: '',
      showOtherHandouts: false,
      isFoundry: false,
      canvas: null,
      handouts: '',
      selectedHandoutCategory: '',
    };
  },
  created() {
    this.isFoundry = window.localStorage.getItem('isFoundry') === 'true' ? true : false;

    this.selectedType = this.$route.params.type;
    this.selectedHandoutCategory = this.selectedType;
    this.selectedVariant = this.$route.params.variant;

    this.handouts = this.sortByKey(generatorData.data);

    if(this.$route.path === '/generator/custom' ){
      this.selectedType = 'custom';
      this.selectedVariant = 'new';
    }

    this.loading = false;
  },
  methods: {
    goToHandout(category, handout) {
      this.$router.push({ path: `/generator/${category}/${handout}`, replace: true });
    },
    prepareClone() {
      if (!this.child) {
        this.$children.forEach((child) => {
          if (child.name === 'canvas') {
            this.child = child;
          }
        });
      }
      this.child.onDeactivated();  
      this.child.prepareClone();
    },
    trackDownload(data) {
      if (window.plausible) {
        data.isPatron = this.isPatron;
        data.category = this.selectedType;
        data.handout = this.selectedVariant;
        window.plausible('Download', { props: data });
      }
    },
    download() {
      this.trackDownload({ platform: 'web' });

      const self = this;
      window.scrollTo(0, 0);

      this.prepareClone();

      setTimeout(() => {
        html2canvas(document.getElementById('canvas'), {
          allowTaint: true,
          useCORS : true,
          backgroundColor: null,
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          windowWidth: document.documentElement.offsetWidth,
          windowHeight: document.documentElement.offsetHeight,
        }).then(function(canvas) {
          self.saveAs(canvas.toDataURL(), 'handout.png');
          self.child.removeClone();
        });
      }, 200);
    },
    importToFoundry() {
      this.trackDownload({ platform: 'foundry' });

      const self = this;
      window.scrollTo(0, 0);

      this.prepareClone();

      const container = document.querySelector('#canvas');
      const matches = container.querySelectorAll('div.value');
      let resultTexts = [];
      for (let i = 0; i < matches.length; i++) {
        resultTexts.push(matches[i].innerHTML);
      }

      setTimeout(() => {
        html2canvas(document.getElementById('canvas'), {
          allowTaint: true,
          backgroundColor: null,
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          windowWidth: document.documentElement.offsetWidth,
          windowHeight: document.documentElement.offsetHeight,
        }).then(function(canvas) {
          window.parent.postMessage(
            {
              action: 'importHandout',
              handoutData: canvas.toDataURL(),
              handoutTexts: resultTexts,
            },
            '*'
          );
          self.child.removeClone();
        });
      }, 200);
    },
    saveAs(uri, filename) {
      var link = document.createElement('a');
      if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },
    sortByKey(obj) {
      obj = [...obj];
      obj = obj.sort((a, b) => (a.name > b.name ? 1 : -1));
      return obj;
    },
  },
};
</script>

<style scoped lang="scss">
hr {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}

.help {
  i {
    font-size: 32px;
    color: #6d842f;
  }
}

.handoutSelection {
  cursor: pointer;
  position: relative;
  background: white;
  border: 1px solid #aaa;
  display: inline-block;
  border-radius: 0.25rem;
  min-width: 240px;

  .handoutSwitcher {
    padding: 5px 10px;
    text-align: left;
  }

  .select-handout-icon {
    position: absolute;
    right: 9px;
    top: 9px;
  }

  .otherHandouts {
    position: absolute;
    top: 35px;
    left: 0;
    background: #eee;
    width: 100%;
    z-index: 1000;
    text-align: center;
    border: 1px solid #aaa;
    border-top: 0;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }

  .category {
    h3 {
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
      background: #6d842f;
      color: white;
      padding: 4px 0;
      margin-bottom: 1px;
    }
    .handout {
      cursor: pointer;
      width: 50%;
      display: inline-block;
      padding: 8px 6px;

      &:hover {
        background: #ddd;
      }
    }
  }
}

.component-wrapper {
  .component-header {
    margin-bottom: 10px;
  }
}

@media (max-width: 992px) {
  .handoutSelection {
    margin-top: 20px;
  }

  .btn-back-wrapper {
    text-align: center;
    .btn-back {
      margin-bottom: 20px;
    }
  }
}
</style>

<template>
    <div id="layers" v-if="handout">
      <h3>Layers</h3>
      <div class="layer-wrapper">
        <div class="layer" 
          :class="{ 'selected': selectedObject === index }" 
          @mouseover="selectHoverObject(index)" 
          @mouseout="selectHoverObject(null)" 
          @click="selectObject(index)" 
          v-for="(layer, index) in objects" 
          :key="layer.name">
          {{ index + 1 }}. {{ layer.name }}
          <i class="fa fa-trash" @click="deleteObject(index)" v-if="selectedObject === index"></i>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    handout: {
      type: Object,
      required: true
    },
    objects: {
      type: Array,
      required: true
    },
    selectedObject: {
      type: Number
    },
    selectedHoverObject: {
      type: Number
    }
  },
  mounted() {
    if(window.location.hostname === 'localhost') {
        this.isLocal = true;
    }
  },
  methods: {
    selectObject(index) {
      this.$emit('object-selected', index);
    },
    selectHoverObject(index) {
      this.$emit('object-hover-selected', index);
    },
    deleteObject(index) {
      this.$emit('object-deleted', index);
    }
  }
}
</script>

<style scoped lang="scss">

#layers {
  position:sticky;
  top:0;
  height:100vh;
}

h3 {
  padding: 5px;
  background:#ddd;
  text-align:center;
  font-size:18px;
  font-weight:bold;
  margin-bottom:0;
}

.fa-trash {
  color: #dc3545;
  border-top:0!important;
  position:absolute;
  right:5px;
  top:9px;
}

.layer-wrapper {
  max-height:calc(100vh - 30px);
  overflow-y:scroll;

  :first-child {
    border-top: 1px solid #ccc;
  }

  .layer {
    position:relative;
    padding: 5px;
    border-bottom: 1px solid #ccc;
    cursor:pointer;
    font-size:16px;

    &:hover {
      background: #eee;
      font-weight:bold;
    }

    &.selected {
      background: #f2ffcd;
      font-weight:bold;
    }
  }
}

</style>
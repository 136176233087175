<template>
    <div id="handout-generator" v-if="handout">

      <div class="row">
        <div class="col-2 sidebar-left">
          <Layers
            :handout="this.handout"
            :objects="this.objects"
            :selectedObject="this.selectedObject"
            :selectedHoverObject="this.selectedHoverObject"
            @object-deleted="deleteObject"
            @object-selected="selectObject"
            @object-hover-selected="selectHoverObject" />
        </div>

        <div class="col-8 canvas-overflow">
          <div class="text-center" v-if="!isPatron">
              <div class="patron-text"><a href="https://www.patreon.com/cthulhu_architect" target="_blank">Support me on Patreon</a> and get full access to all the handouts without a watermark and support the further development of the handout generator.</div>
          </div>

          <Ads/>

          <div class="text-center">
            <div class="handout-wrapper">
              <div class="toolbox">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-primary" @click="addObject('text')"><i class="la la-comment"></i> Add Text</button>
                    <button class="btn btn-primary" @click="addObject('image')"><i class="la la-image"></i> Add Image</button>
                    <template v-if="this.type === 'custom'">
                      <input type="file" @change="onFileChangeBG" style="display:none;" accept="image/*" name="fileBG1" ref="fileBG">
                      <button class="btn btn-primary" @click="triggerUploadBG()"><i class="la la-image"></i> Add Background</button>
                    </template>
                  </div>
                  <div class="col text-right">
                    <button class="btn btn-secondary" :disabled="!isPatron" title="This feature is only available to patrons" @click="importData()"><i class="la la-file-import"></i> Import</button>
                    <button class="btn btn-secondary" :disabled="!isPatron" title="This feature is only available to patrons" @click="exportData(handout)"><i class="la la-file-export"></i> Export</button>
                    <a id="downloadAnchorElem" style="display:none"></a>
                    <button class="btn btn-secondary" @click="loadData()"><i class="la la-redo"></i> Reset</button>
                    <button class="btn btn-danger" @click="deleteAllObjects()"><i class="la la-trash"></i> Delete all</button>
                  </div>
                </div>
              </div>

              <p class="warning text-center"  v-if="handout.warning"><b>WARNING:</b> {{ handout.warning }}</p>

              <div id="canvas" @click="clickCanvas($event)" :style="{
                backgroundImage: this.type === 'custom' ? 'url(' + this.handout.image + ')' : 'url(/images/' + this.type + '/' + getHandoutImage() + ')',
                width: this.handout.width + 'px',
                height: this.handout.height + 'px',
                }">
                  <vue-draggable-resizable
                      v-for="(obj, index) in objects" v-bind:key="index"
                      :id="obj.name"
                      :x="obj.x"
                      :y="obj.y"
                      :w="obj.width"
                      :h="obj.height"
                      :minWidth="obj.type === 'image' ? 20 : 20"
                      :minHeight="26"
                      drag-handle=".control-move"
                      :active="selectedObject === index ? true : false"
                      :prevent-deactivation="true"
                      @dragging="onDrag"
                      @resizing="onResize"
                      :class="{'disabled': selectedObject !== index, 'hover-selected': selectedHoverObject === index}"
                      :style="{zIndex: selectedObject === index ? 100000 : obj.zIndex}"
                      :parent="true">

                          <div v-if="!index && showClickHere" class="click-here">Click on an element to edit it <i class="la la-arrow-right"></i></div>

                          <div class="content" v-if="obj.type === 'text'"
                              :style="{fontFamily: obj.customFont ? obj.customFont : ''}"
                              :class="[obj.customFont ? '' : 'apply-font-object' + handout.id + obj.name]">

                              <div class="value div" v-if="selectedObject !== index"
                              @click="onActivated(index);"
                              :style="{
                                  height: obj.height + 'px',
                                  opacity: obj.opacity + '%',
                                  color: obj.fontColor,
                                  textAlign: obj.textAlign,
                                  lineHeight: obj.lineHeight + 'px',
                                  letterSpacing: obj.letterSpacing + 'px',
                                  fontSize: obj.fontSize + 'px',
                                  fontWeight: obj.fontWeight,
                                  fontStyle: obj.fontStyleItalic ? 'italic' : 'normal',
                                  textDecoration: obj.fontStyleUnderline ? 'underline' : 'none',
                                  transform: 'rotate(' + obj.rotation + 'deg)'}" v-html="getHtmlValue(obj.value)">
                              </div>
                              <textarea class="value" v-model="obj.value"
                              v-if="selectedObject === index"
                              :style="{
                                  height: obj.height + 'px',
                                  opacity: obj.opacity + '%',
                                  color: obj.fontColor,
                                  textAlign: obj.textAlign,
                                  lineHeight: obj.lineHeight + 'px',
                                  letterSpacing: obj.letterSpacing + 'px',
                                  fontSize: obj.fontSize + 'px',
                                  fontWeight: obj.fontWeight,
                                  fontStyle: obj.fontStyleItalic ? 'italic' : 'normal',
                                  textDecoration: obj.fontStyleUnderline ? 'underline' : 'none',
                                  transform: 'rotate(' + obj.rotation + 'deg)'}"
                              ></textarea>
                          </div>

                          <div class="content" v-if="obj.type === 'image'">
                              <div class="image"
                              @click="onActivated(index);"
                              :style="{
                                  background: 'url(' + obj.value + ')',
                                  width: obj.width + 'px',
                                  height: obj.height + 'px',
                                  opacity: obj.opacity + '%',
                                  transform: 'rotate(' + obj.rotation + 'deg)'}"></div>
                          </div>

                          <div class="controls" v-if="selectedObject === index">


                              <!-- IMAGE CONTROLS -->
                              <template v-if="obj.type === 'image'">
                                <div class="control control-choose-image" title="Choose an image">
                                  <input type="file" @change="onFileChange" class="photo-upload" name="filename" ref="file">
                                  <i class="fa fa-upload" @click="triggerUpload()"></i>
                                </div>
                              </template>


                              <div class="control control-delete" @click="deleteObject(index)" title="Delete">
                                  <i class="fa fa-trash"></i>
                              </div>

                              <div class="control-dummy control-move" title="Move">
                                  <i class="fa fa-arrows-alt"></i>
                              </div>

                          </div>

                          <div class="font-family-wrapper" v-if="obj.type === 'text'" :class="{'vis-hidden': !obj.showFontFamilies}">
                              <google-font-picker :name="'object' + handout.id + obj.name" :defaultFont="obj.fontFamily" :selectedFonts="handout.defaultFonts" @customFont="(font) => {obj.customFont = font; obj.showFontFamilies = false;}"></google-font-picker>
                          </div>


                  </vue-draggable-resizable>
              </div>

              <p class="license" v-if="handout.license === 'ccby40'"><a rel="license" href="http://creativecommons.org/licenses/by/4.0/"><img alt="Creative Commons License" style="border-width:0" src="https://i.creativecommons.org/l/by/4.0/88x31.png" loading="lazy" /></a><br />This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License</a>.</p>
              <p class="license" v-if="handout.license === 'byncnd40'"><a rel="license" href="https://creativecommons.org/licenses/by-nc-nd/4.0/"><img alt="Creative Commons License" style="border-width:0" src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png" loading="lazy" /></a><br />This work is licensed under a <a rel="license" href="https://creativecommons.org/licenses/by-nc-nd/4.0/">Attribution-NonCommercial-NoDerivatives 4.0 International</a>.</p>
              <p class="attribution"  v-if="handout.attribution"><b>Attribution:</b> <span v-html="handout.attribution"></span></p>
            </div>
          </div>
        </div>

        <div class="col-2 sidebar-right">
          <Controls :handout="this.handout" :objects="this.objects" :selectedObject="this.selectedObject" @change-data="changeData" />
        </div>
      </div>


    </div>
</template>

<script>
import Ads from '../components/Ads.vue';
import Layers from '../components/Layers.vue';
import Controls from '../components/Controls.vue';
import GoogleFontPicker from '../components/GoogleFontPicker.vue';
import generatorData from '@/models/generator.json';

export default {
  props: {
    type: { type:String, default: 'letters'},
    variant: { type:String, default: 'letter_a' }
  },
  components: {GoogleFontPicker, Layers, Controls, Ads},
  data: function () {
    return {
      name: 'canvas',
      showClickHere: true,
      isLocal: true,
      emptyObjects: {
        text: {},
        image: {}
      },
      handout: null,
      objects: [],
      selectedObject: null,
      selectedHoverObject: null
    }
  },
  mounted() {
    if(window.location.hostname === 'localhost') {
        this.isLocal = true;
    }
    this.loadData();
  },
  methods: {
    async loadData() {
      let response = null;
      let data = null;

      const localStorageData = localStorage.getItem('handoutData');
      if (localStorageData) {
        data = JSON.parse(localStorageData);
        localStorage.removeItem('handoutData');
      } else {
        response = await fetch('/models/' + this.type + '/' + this.variant + '.json');
        data = await response.json();
      }

      const response2 = await fetch('/models/default-text.json');
      const response3 = await fetch('/models/default-image.json');

      const defaultObjectText = await response2.json();
      const defaultObjectImage = await response3.json();

      this.emptyObjects.text = defaultObjectText;
      this.emptyObjects.image = defaultObjectImage;

      for (let i = 0; i < data.objects.length; i++) {
        const object = data.objects[i];

        if (object.type === 'text') {
          const merged = {...defaultObjectText, ...object};
          data.objects[i] = merged;
        }

        if (object.type === 'image') {
          const merged = {...defaultObjectImage, ...object};
          data.objects[i] = merged;
        }
      }

      this.objects = data.objects;

      this.handout = data;
      delete this.handout.objects;
    },
    onResize: function (x, y, width, height) {
      if (x) this.objects[this.selectedObject].x = x;
      if (y) this.objects[this.selectedObject].y = y;
      if (width) this.objects[this.selectedObject].width = width;
      if (height) this.objects[this.selectedObject].height = height;
    },
    onDrag: function (x, y) {
      if (x) this.objects[this.selectedObject].x = x
      if (y) this.objects[this.selectedObject].y = y
    },
    onActivated: function (index) {
      this.selectedObject = null;
      for (let i = 0; i < this.objects.length; i++) {
        this.objects[i].showFontFamilies = false;
      }

      this.$nextTick().then(() => {
        // Add the component back in
        this.showClickHere = false;
        this.selectedObject = index;
      });

    },
    onDeactivated() {
      this.selectedObject = null;
      for (let i = 0; i < this.objects.length; i++) {
        this.objects[i].showFontFamilies = false;
      }
    },
    triggerUpload() {
      this.$refs.file[0].click();
    },
    triggerUploadBG() {
      if (this.isPatron) {
        this.$refs.fileBG.click();
      } else {
        alert('Handouts with custom backgrounds are only available for patrons. Please consider supporting the project on Patreon.')
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.objects[this.selectedObject].value = URL.createObjectURL(file);
    },
    onFileChangeBG(e) {
      const file = e.target.files[0];

      this.handout.image = URL.createObjectURL(file);

      const img = new Image();
      const self = this;
      img.onload = function () {
        self.handout.width = this.width;
        self.handout.height = this.height;
      };
      img.src = this.handout.image;
    },
    addObject(type) {
      const object = JSON.parse(JSON.stringify(this.emptyObjects[type]));
      object.name = object.name + (this.objects.length + 1);
      if (object.type === 'text') {
        object.value = object.value + (this.objects.length + 1);
      }

      this.objects.push(object);
    },
    deleteObject(index) {
        this.selectedObject = null;
        this.objects.splice(index, 1);
    },
    deleteAllObjects() {
        this.selectedObject = null;
        this.objects = [];
    },
    prepareClone() {
      this.showClickHere = false;
    },
    removeClone() {
      // Nothing yet
    },
    exportData(data) {
      data['objects'] = this.objects;
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data, null, 4));
      var dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute("href",     dataStr     );
      dlAnchorElem.setAttribute("download", data.id + ".json");
      dlAnchorElem.click();
    },
    importData() {
      var input = document.createElement('input');
      input.type = 'file';
      // allow only json files
      input.accept = '.json';

      input.onchange = e => {
        // getting a hold of the file reference
        var file = e.target.files[0];
        // setting up the reader
        var reader = new FileReader();
        reader.readAsText(file,'UTF-8');
        // here we tell the reader what to do when it's done reading...
        reader.onload = readerEvent => {
            var content = readerEvent.target.result; // this is the content!
            const data = JSON.parse(content);

            const handoutCategories = generatorData.data;
            for (let i = 0; i < handoutCategories.length; i++) {
              const category = handoutCategories[i];
              for (let j = 0; j < category.variants.length; j++) {
                const variant = category.variants[j];
                if (data.image.indexOf(variant.name) === 0) {
                  localStorage.setItem('handoutData', JSON.stringify(data));
                  this.$router.push({ path: `/generator/${category.folderName}/${variant.name}`, replace: true });
                  if (this.type === category.folderName && this.variant === variant.name) {
                    this.loadData();
                  }
                }
              }
            }
        }
      }

      input.click();
    },
    selectObject(index) {
      this.selectedObject = index;
    },
    selectHoverObject(index) {
      this.selectedHoverObject = index;
    },
    changeData(option, value) {
      this.objects[this.selectedObject][option] = value;
    },
    clickCanvas(event) {
      if (event.target.id === 'canvas') {
        this.onDeactivated();
      }
    },
    getHtmlValue(text) {
      return text.split('\n').join('<br/>');
    },
    getHandoutImage() {
      if (this.isPatron && this.handout.sec) {
        return atob(this.handout.sec);
      }

      return this.handout.image;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/v2";
</style>
<template>
    <div class="promo" v-if="!isPatron">
      <a v-if="randomAd === 1" href="https://www.drivethrurpg.com/browse.php?filters=0_0_220_0_0&src=affiliate2525050&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/themes/dtrpg/images/728X90Cthulhu.png" border="0" title="The best cosmic horror & Cthulhu Mythos @ DriveThruRPG.com" alt="Banner: The best cosmic horror & Cthulhu Mythos @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 2" href="https://www.drivethrurpg.com/saleguide.php?affiliate_id=2525050"><img src="https://www.drivethrurpg.com/images/site_resources/DTRPG-HugeDiscounts-BannerB.png" border="0" alt="Huge Discounts on your Favorite RPGs @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 3" href="https://www.drivethrurpg.com/metal.php?affiliate_id=2525050"><img src="https://www.drivethrurpg.com/images/site_resources/DTRPG-PlatinumBan.jpg" border="0" alt="Best Selling RPGs - Available Now @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 4" href="https://www.drivethrurpg.com/FILENAME_DEFAULT?filters=240_0_0_0_0&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/images/site_resources/DTRPG-ZombieSale-Banner.jpg" border="0" alt="Zombies! - Available Now @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 5" href="https://www.drivethrurpg.com/browse.php?filters=0_0_1300_0_0&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/images/site_resources/OldSchoolRPGs-Banner.gif" border="0" alt="Old School RPGs - Available Now @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 6" href="https://www.drivethrurpg.com/browse.php?filters=600_0_0_0_0&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/themes/dtrpg/images/Superhero-Banner-DTRPG-Revised.jpg" border="0" alt="Superheroes - Available Now @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 7" href="https://www.drivethrurpg.com/top_100_small_press.php?src=affiliate2525050&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/themes/dtrpg/images/728x90indies.png" border="0" title="Home to Incredible Indies - Available Now @ DriveThruRPG.com" alt="Banner: Home to Incredible Indies - Available Now @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 8" href="https://www.drivethrurpg.com/browse.php?filters=0_0_100003_0_0&src=affiliate2525050&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/themes/dtrpg/images/mothership_728.png" border="0" title="Mothership Sci-Fi Horror RPG from Tuesday Knight Games available @ DriveThruRPG.com" alt="Banner: Mothership Sci-Fi Horror RPG from Tuesday Knight Games available @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 9" href="https://www.drivethrurpg.com/browse/pub/4329/Modiphius/subcategory/39638/Fallout-The-Roleplaying-Game?src=affiliate2525050&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/themes/dtrpg/images/728_fallout.png" border="0" title="Welcome to the Wasteland! Fallout the Roleplaying Game from Modiphius Entertainment available @ DriveThruRPG.com" alt="Banner: Welcome to the Wasteland! Fallout the Roleplaying Game from Modiphius Entertainment available @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 10" href="https://www.drivethrurpg.com/browse.php?filters=0_0_100226_0_0&src=affiliate2525050&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/themes/dtrpg/images/728x90forged.png" border="0" title="Forged in the Dark - available @ DriveThruRPG.com" alt="Banner: Forged in the Dark - available @ DriveThruRPG.com" /></a>
      <a v-if="randomAd === 11" href="https://www.drivethrurpg.com/browse.php?filters=1830_0_0_0_0&src=affiliate2525050&affiliate_id=2525050"><img src="https://www.drivethrurpg.com/themes/dtrpg/images/wod728x90dtrpg.png" border="0" title="Explore the World of Darkness @ DriveThruRPG.com" alt="Banner: Explore the World of Darkness @ DriveThruRPG.com" /></a>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
      randomAd: 0,
    }
  },
  mounted() {
    // Get a random number between 1 and 6
    this.randomAd = Math.floor(Math.random() * 11) + 1;
  }
}
</script>

<style scoped lang="scss">
.promo {
  height:90px;
}
</style>
<template>
    <div id="controls" v-if="handout">
      <h3>Controls</h3>
      <div class="control-wrapper">
        <p v-if="selectedObject === null">Select a layer to edit it.</p>

        <template v-if="selectedObject !== null">
          <div class="control">
            <label>Position / Dimensions</label>
            <div class="row">
              <div class="col-6">
                <div class="input-group input-group-sm mb-1">
                  <div class="input-group-prepend">
                    <span class="input-group-text">X</span>
                  </div>
                  <input type="number" class="form-control" v-model="objects[selectedObject].x">
                </div>
              </div>
              <div class="col-6">
                <div class="input-group input-group-sm mb-1">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Y</span>
                  </div>
                  <input type="number" class="form-control" v-model="objects[selectedObject].y">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="input-group input-group-sm mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Z</span>
                  </div>
                  <input type="number" class="form-control" v-model="objects[selectedObject].zIndex">
                  <i title="Change the z-index of an object. While the object is selected, it always appears on top, you need to deselect it in order to appear to the specified z-index" class="fa fa-info-circle info-tooltip"></i>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="input-group input-group-sm mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">W</span>
                  </div>
                  <input type="number" class="form-control" v-model="objects[selectedObject].width">
                </div>
              </div>
              <div class="col-6">
                <div class="input-group input-group-sm mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">H</span>
                  </div>
                  <input type="number" class="form-control" v-model="objects[selectedObject].height">
                </div>
              </div>
            </div>
          </div>

          <template  v-if="objects[selectedObject].type === 'text'">
            <div class="row">
              <div class="col">
                <div class="control mb-1">
                  <label>Font style</label><br/>
                  <div class="controlBtn" @click="changeData('fontWeight', objects[selectedObject].fontWeight === 'bold' ? 'normal': 'bold')" title="Bold" :class="{ selected: objects[selectedObject].fontWeight === 'bold' }">
                    <i class="fa fa-bold"></i>
                  </div>
                  <div class="controlBtn" @click="changeData('fontStyleItalic', !objects[selectedObject].fontStyleItalic)" title="Italic" :class="{ selected: objects[selectedObject].fontStyleItalic }">
                    <i class="fa fa-italic"></i>
                  </div>
                  <div class="controlBtn" @click="changeData('fontStyleUnderline', !objects[selectedObject].fontStyleUnderline)" title="Underline" :class="{ selected: objects[selectedObject].fontStyleUnderline }">
                    <i class="fa fa-underline"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="control mb-1">
                  <label>Text color</label><br/>
                  <v-input-colorpicker v-model="objects[selectedObject].fontColor" />
                </div>
              </div>
            </div>
            

            <div class="control mb-2">
              <label>Text align</label><br/>
              <div class="controlBtn" @click="changeData('textAlign', 'left')" title="Left" :class="{ selected: objects[selectedObject].textAlign === 'left' }">
                <i class="fa fa-align-left"></i>
              </div>
              <div class="controlBtn" @click="changeData('textAlign', 'right')" title="Right" :class="{ selected: objects[selectedObject].textAlign === 'right' }">
                <i class="fa fa-align-right"></i>
              </div>
              <div class="controlBtn" @click="changeData('textAlign', 'center')" title="Center" :class="{ selected: objects[selectedObject].textAlign === 'center' }">
                <i class="fa fa-align-center"></i>
              </div>
              <div class="controlBtn" @click="changeData('textAlign', 'justify')" title="Justify" :class="{ selected: objects[selectedObject].textAlign === 'justify' }">
                <i class="fa fa-align-justify"></i>
              </div>
            </div>

            <div class="control mb-1">
              <label>Font Family</label><br/>
              <google-font-picker 
                :name="'object' + handout.id + objects[selectedObject].name" 
                :defaultFont="objects[selectedObject].fontFamily" 
                :selectedFonts="handout.defaultFonts" 
                @changeFont="changeFont"
                @customFont="(font) => {objects[selectedObject].customFont = font; objects[selectedObject].showFontFamilies = false;}"></google-font-picker>
            </div>


            <div class="control">
              <label>Font size</label><br/>
              <div class="row">
                <div class="col-6">
                  <input type="range" class="form-control-range" v-model="objects[selectedObject].fontSize" min="1" max="120">
                </div>
                <div class="col-6">
                  <div class="input-group input-group-sm mb-3">
                    <input type="number" class="form-control" v-model="objects[selectedObject].fontSize">
                    <div class="input-group-append">
                      <span class="input-group-text">px</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="control">
              <label>Line height</label><br/>
              <div class="row">
                <div class="col-6">
                  <input type="range" class="form-control-range" v-model="objects[selectedObject].lineHeight" min="1" max="150">
                </div>
                <div class="col-6">
                  <div class="input-group input-group-sm mb-3">
                    <input type="number" class="form-control" v-model="objects[selectedObject].lineHeight">
                    <div class="input-group-append">
                      <span class="input-group-text">px</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="control">
              <label>Letter spacing</label><br/>
              <div class="row">
                <div class="col-6">
                  <input type="range" class="form-control-range" v-model="objects[selectedObject].letterSpacing" min="0" max="20">
                </div>
                <div class="col-6">
                  <div class="input-group input-group-sm mb-3">
                    <input type="number" class="form-control" v-model="objects[selectedObject].letterSpacing">
                    <div class="input-group-append">
                      <span class="input-group-text">px</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="control">
            <label>Rotation</label><br/>
            <div class="row">
              <div class="col-6">
                <input type="range" class="form-control-range" v-model="objects[selectedObject].rotation" min="-180" max="180">
              </div>
              <div class="col-6">
                <div class="input-group input-group-sm mb-3">
                  <input type="number" class="form-control" v-model="objects[selectedObject].rotation">
                  <div class="input-group-append">
                    <span class="input-group-text">deg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="control mb-3">
            <label>Opacity</label><br/>
            <div class="row">
              <div class="col-6">
                <input type="range" class="form-control-range" v-model="objects[selectedObject].opacity" min="0" max="100">
              </div>
              <div class="col-6">
                <div class="input-group input-group-sm mb-3">
                  <input type="number" class="form-control" v-model="objects[selectedObject].opacity">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </template>
      </div>
    </div>
</template>

<script>
import GoogleFontPicker from './GoogleFontPicker.vue';

export default {
  props: {
    handout: {
      type: Object,
      required: true
    },
    objects: {
      type: Array,
      required: true
    },
    selectedObject: {
      type: Number
    }
  },
  components: {GoogleFontPicker},
  data: function () {
    return {
      test: null
    }
  },
  mounted() {
    if(window.location.hostname === 'localhost') {
        this.isLocal = true;
    }
  },
  methods: {
    changeData(option, value) {
      this.$emit('change-data', option, value);
    },
    changeFont(font) {
      this.$emit('change-data', 'fontFamily', font);
    }
  }
}
</script>

<style scoped lang="scss">

#controls {
  position:sticky;
  top:0;
  height:100vh;
}

h3 {
  padding: 5px;
  background:#ddd;
  text-align:center;
  font-size:18px;
  font-weight:bold;
  margin-bottom:0;
}

.info-tooltip {
  position: absolute;
  right:-24px;
  top:7px;
  cursor:pointer;
}

label {
  font-size:12px!important;
}

.control-wrapper {
  max-height:calc(100vh - 30px);
  overflow-y:auto;
  overflow-x:hidden;
  padding:5px 10px;
  

  .controlBtn {
    background: white;
    padding: 0 5px;
    border-radius: 5px;
    border: 1px solid #aaa;
    cursor: pointer;
    min-width: 26px;
    margin-right: 5px;
    text-align: center;
    display:inline-block;

    &.selected {
        background: #6d842f;
        color: white;
    }
  }
}

.input-group {
  margin-bottom:6px!important;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  outline: none;
  margin: 5px 0;
  position:relative;
  top:5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #6D842F;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #6D842F;
  border-radius: 50%;
  cursor: pointer;
}


</style>